// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tieple_web_UI-primary: mat.define-palette(mat.$indigo-palette);
$tieple_web_UI-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tieple_web_UI-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tieple_web_UI-theme: mat.define-light-theme((
  color: (
    primary: $tieple_web_UI-primary,
    accent: $tieple_web_UI-accent,
    warn: $tieple_web_UI-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tieple_web_UI-theme);

/* Tieple Font */
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url(./assets/fonts/Poppins/Poppins-Regular.woff) format("Web Open Font Format");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"), url(./assets/fonts/Montserrat/Montserrat-Regular.woff) format("Web Open Font Format");
}

$font-family-primary: '"Poppins-Regular", sans-serif';
$font-family-accent: '"Montserrat-Regular", sans-serif';

$tieple-custom-typography: mat.define-typography-config(
  $display-4: mat.define-typography-level(112px, 112px, 300, $font-family-primary, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(56px, 56px, 400, $font-family-primary, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(45px, 48px, 400, $font-family-primary, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 400, $font-family-primary),
  $headline: mat.define-typography-level(24px, 32px, 400, $font-family-accent),
  $title: mat.define-typography-level(20px, 32px, 500, $font-family-primary),
  $subheading-2: mat.define-typography-level(16px, 28px, 400, $font-family-primary),
  $subheading-1: mat.define-typography-level(15px, 24px, 400, $font-family-accent),
  $body-2: mat.define-typography-level(14px, 24px, 500, $font-family-primary),
  $body-1: mat.define-typography-level(14px, 20px, 400, $font-family-primary),
  $caption: mat.define-typography-level(12px, 20px, 400, $font-family-primary),
  $button: mat.define-typography-level(14px, 14px, 500, $font-family-primary),
  $input: mat.define-typography-level(inherit, 1.125, 400, $font-family-primary)
);
@include mat.core($tieple-custom-typography);

/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  height: 100vh;
  overflow: hidden
}

